<template>
  <div>
    <time-item class="mb-4"
      v-for="(item, index) in value"
      :key="index"
      v-model="array[index]"
    />
  </div>
 
</template>

<script>
import pivotMixins from "@/components/eaglePlugin/pivotMixins.js";
export default {
  mixins: [pivotMixins],
  components: {
    "time-item": () => import("./timeItem.vue"),
  },
};
</script>